<template>
  <body
    class="dark kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
    <section class="vh-100" style="background-color: #101826;">
      <div class="container-fluid py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-xl-11">
            <div class="card border-0" style="border-radius: 1rem; background-color: #101826;">
              <div class="row g-0">
                <h1 class="fw-normal pb-3 text-white" v-if="is_not_valid" style="letter-spacing: 1px;">
                  This link has expired. Don't worry, you can request a fresh one to update your password.<br />
                </h1>
                <div class="col-md-6 col-lg-5 d-flex align-items-center">
                  <div class="card-body p-4 p-lg-5">

                    <form v-if="is_valid">
                      <h1 class="fw-normal pb-3 text-white" style="letter-spacing: 1px;">
                        Reset your password<br />
                      </h1>
                      <h6 class="fw-normal pb-3 text-white">
                        Enter your new password and we'll update your password and get back into your account.<br />
                      </h6>

                      <div class="form-outline mb-4">
                        <label class="form-label text-white" for="form2Example27">Password</label>

                        <div class="form-outline input-group mb-4">
                          <input v-if="showPassword" type="text" id="form2Example27"
                            class="form-control form-control-lg my" v-model="password" />
                          <input v-else type="password" id="form2Example27" class="form-control form-control-lg my"
                            v-model="password" />
                          <div class="input-group-append" @click="hidePassword">
                            <span class="input-group-text" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                <path
                                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path
                                  d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                              </svg></span>
                          </div>
                        </div>
                      </div>
                      <div class="form-outline mb-4">
                        <label class="form-label text-white" for="form2Example27">Confirm Password</label>

                        <div class="form-outline input-group mb-4">
                          <input v-if="c_showPassword" type="text" id="form2Example27"
                            class="form-control form-control-lg my" v-model="c_password" />
                          <input v-else type="password" id="form2Example27" class="form-control form-control-lg my"
                            v-model="c_password" />
                          <div class="input-group-append" @click="c_hidePassword">
                            <span class="input-group-text" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg"
                                width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                <path
                                  d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                <path
                                  d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                              </svg></span>
                          </div>
                        </div>
                      </div>

                      <div style="color: #ff8b00; font-weight: bold">
                        <em>{{ validateError }}</em>
                      </div>

                      <div class="pt-1 mb-4">
                        <button v-on:click="ResetPassword()" style="position: relative;"
                          class="btn btn-primary btn-lg btn-block" type="button" :disabled="is_button">
                          Next
                          <span v-if="loader" class="spinner spinner-white spinner-right"></span>
                        </button>
                      </div>

                      <p class="mb-5 pb-lg-2" style="color: #ffff;">
                        Back to
                        <a href="#!" style="color: #2d8cff;">
                          <router-link to="login">Login </router-link>
                        </a>
                      </p>
                    </form>
                  </div>
                </div>

                <div class="col-md-6 col-lg-7 d-none d-md-block" v-if="is_valid" style="
                    color: #78b0ed;
                    background-image: linear-gradient(
                      200deg,
                      rgba(172, 206, 247, 1),
                      rgba(240, 210, 238, 1)
                    );
                    border-radius: 1rem 1rem;
                  ">
                  <div class="fw-normal mt-5 mb-2 pb-5 text-dark text-center">
                    <img alt="Logo" width="350" height="62" src="../../assets/media/UW@600x.png" />
                  </div>
                  <div class="text-center mb-5" style="color: #3e4c64;">
                    <img src="../../assets/media/broadcast.jpg" alt="login form" class="img-fluid w-50"
                      style="border-radius: 1rem 0 0 1rem;" />
                    <h4 style="color: #3e4c64;">
                      The <b>Most Powerful</b> webinar software
                    </h4>
                    <p>
                      webinar software that puts you in control of your
                      broadcasts.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</template>

<script>
import { loginPost, checkResetLink } from "../services/service";
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      email: "",
      password: "",
      c_password: "",
      showPassword: false,
      c_showPassword: false,
      loader: false,
      is_button: false,
      is_valid: false,
      is_not_valid: false,
    };
  },
  async created() {
    // let urlParams = new URLSearchParams(window.location.search);
    // let reset_link = urlParams.get("reset-auth");
    // if (urlParams.has("reset-auth")) {
    //   var body = {
    //     reset_link: reset_link,
    //   };

    //   const data = await checkResetLink(body);
    //   console.log('data-------------', data);
    //   if (data.data.result == 2) {
    //     this.is_valid = false;
    //     this.is_not_valid = true;
    //   } else {
    //     this.is_not_valid = false;
    //     this.is_valid = true;
    //   }
    // } else {
    //   if (localStorage.getItem("isAuthenticated") == null) {
    //     this.$router.push("/calendar");
    //   }
    // }
      let urlParams = new URLSearchParams(window.location.search);
      let reset_link = urlParams.get("reset-auth");
  if (urlParams.has("reset-auth")) {
    const body = {
      reset_link: reset_link,
    };

    const data = await checkResetLink(body);
    console.log("data-------------", data);
      if (data.data.result !== 1) {
      this.is_valid = false;
      this.is_not_valid = true;
    }else{
            this.is_valid = true;
      this.is_not_valid = false;
    }
  } else {
    if (!localStorage.getItem("isAuthenticated")) {
      this.$router.push("/calendar");
    }
  }
  },
  methods: {
    ...mapActions(["LogIn"]),
    async hidePassword() {
      this.showPassword = !this.showPassword;
    },
    async c_hidePassword() {
      this.c_showPassword = !this.c_showPassword;
    },
    // async ResetPassword() {
    //   let urlParams = new URLSearchParams(window.location.search);

    //   var body = {
    //     reset_link: urlParams.get("reset-auth"),
    //     password: this.password,
    //     is_update: 1

    //   };
    //   console.log('body---', body)
    //   if (this.password == "") {
    //     swal("Please enter password.");
    //   } else if (this.c_password == "") {
    //     swal("Please enter confirm password.");
    //   } else {
    //     if (this.password == this.c_password) {
    //       this.loader = true;

    //       // let data = await 
    //       const data = await checkResetLink(body);
    //       if (data.data.result == 2) {
    //         swal({
    //           title: "Password updated",
    //           text:
    //             "Password has been updated. Please login with new password.",
    //           icon: "success",
    //           dangerMode: false,
    //         }).then((willDelete) => {
    //           if (willDelete) {
    //             this.$router.push("/login");
    //           } else {
    //             this.$router.push("/login");
    //             // this.$router.push("/calendar");
    //           }
    //         });
    //       } else if (data.data.result == 4) {
    //         swal(data.data.message);

    //       } else {
    //         this.is_valid = false;
    //         this.is_not_valid = true;
    //       }
    //       this.loader = false;
    //     } else {
    //       swal("Passowrd do not match.");
    //     }
    //   }
    // },
async ResetPassword() {
  // Extract reset link from URL
  let urlParams = new URLSearchParams(window.location.search);

  // Construct request body
  const body = {
    reset_link: urlParams.get("reset-auth"), // Reset token from URL
    password: this.password, // User's new password
    is_update: 1, // Indicates an update
  };

  console.log("Request body:", body);

  // Validation for password fields
  if (!this.password) {
    swal("Please enter a password.");
    return;
  }
  if (!this.c_password) {
    swal("Please enter the confirm password.");
    return;
  }
  if (this.password !== this.c_password) {
    swal("Passwords do not match.");
    return;
  }

  // Show loader during the API call
  this.loader = true;

  try {
    // Call the backend API
    const data = await checkResetLink(body);

    // Handle the API response
    switch (data.data.result) {
      case 4:
        this.is_valid = false;
        this.is_not_valid = true;
        swal(data.data.message); // Show a custom message from the server
        break;

      case 2:
        swal({
          title: "Password updated!",
          text: "Your password has been successfully updated. You can now log in with the new password.",
          icon: "success",
          dangerMode: false,
        }).then(() => {
          this.$router.push("/login"); // Redirect to the login page after success
        });
        break;

      default:
        this.is_valid = false;
        this.is_not_valid = true;
        break;
    }
  } catch (error) {
    // Catch and handle errors (network issues, etc.)
    console.error("Error during password reset:", error);
    swal("An error occurred while resetting your password. Please try again later.");
  } finally {
    // Hide loader
    this.loader = false;
  }
}
,
    async isEmailValid() {
      if (this.reg.test(this.email) == false) {
        this.validateError = "Please enter valid Email";
        this.is_button = true;
      } else {
        this.validateError = "";
        this.is_button = false;
      }
      console.log('hiii')
    }

  },
};
</script>

<style>
.my {
  background: white !important;
}
</style>
